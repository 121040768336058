.hint[data-v-08994ca4] {
  margin-left: 5px;
}
.name_box[data-v-08994ca4] {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 10px;
}
.name_box h5[data-v-08994ca4] {
  display: inline-block;
  margin: 0 auto;
  font-size: 20px;
  color: #d56400;
  text-align: center;
  border-bottom: 4px double #d56400;
  line-height: 40px;
  position: relative;
  padding: 0 28px 0 4px;
  font-weight: 400;
}
.name_box h5 span[data-v-08994ca4] {
  position: absolute;
  top: 13px;
  right: 3px;
  width: 0;
  height: 0;
  border-bottom: 16px solid #d56400;
  border-left: 16px solid transparent;
}
.fee[data-v-08994ca4] {
  color: #008000 !important;
  border-bottom-color: #008000 !important;
}
.top_select[data-v-08994ca4] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}